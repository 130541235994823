<template>
  <DashboardTemplate
    v-loading.fullscreen.lock="loading"
    element-loading-text="กำลัง Sync ข้อมูล DBD รายบุคคล"
  >
    <div class="pd-x-5">
      <el-row>
        <el-col :span="24">
          <div class="is-flex ai-center js-between">
            <h2>ข้อมูลบริษัท</h2>
            <div>
              <!-- <el-button
                :loading="loading"
                type="primary"
                @click="sysDbdByUserId($route.params.id)"
                >DBD Sync</el-button
              > -->
              <el-button
                v-if="!yearNow"
                type="primary"
                @click="
                  lastCompanyId != null
                    ? $router.push(
                        `/system/user/company/add/${$route.params.id}/${lastCompanyId}`
                      )
                    : $router.push(
                        `/system/user/company/add/${$route.params.id}`
                      )
                "
                >เพิ่มข้อมูลบริษัท</el-button
              >
            </div>
          </div>
        </el-col>
        <el-col :span="24">
          <el-form ref="filter" :model="filter" label-position="top">
            <div class="bordered rounded pd-2 pd-t-4 pd-b-5 mg-b-3">
              <el-row>
                <el-col :span="8" :offset="0">
                  <el-input
                    v-model="filter.search"
                    placeholder="ค้นหา ชื่อ/นามสกุล/อีเมล"
                    clearable
                    @input="handleCurrentChange(1, 600)"
                  ></el-input>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </el-col>
        <el-col :span="24" :offset="0">
          <!-- <pre>{{ userList }}</pre> -->
          <!-- <div class="text-right mg-b-5 text-grey-light font-16">
            ข้อมูลจาก DBD
            <span v-if="lastSyncDBD">{{ lastSyncDBD | dateTimeTh }}</span>
            <span v-else>-</span>
          </div> -->
          <el-table border :data="userList" style="width: calc(100% - 5px)">
            <template slot="empty">
              <div class="noData">ไม่พบข้อมูลที่ค้นหา</div>
            </template>
            <el-table-column
              prop="name"
              label="ชื่อ"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.type_committee"
                  placement="bottom-start"
                  v-if="
                    scope.row.type_committee != '-' &&
                    scope.row.type_committee != ''
                  "
                >
                  <span class="text-primary">
                    <i class="fas fa-user-tie"></i>
                  </span>
                </el-tooltip>
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column
              prop="surname"
              label="นามสกุล"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              prop="email"
              label="อีเมล"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              prop="companyName"
              label="ชื่อบริษัท"
              align="center"
              header-align="center"
            ></el-table-column>
            <el-table-column
              prop="corporateNumber"
              label="เลขนิติบุคคล"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <div
                  :class="{
                    'text-danger':
                      !scope.row.dbd ||
                      scope.row.corporateNumber == '0000000000000',
                  }"
                >
                  {{ scope.row.corporateNumber }}
                  <i
                    v-if="
                      !scope.row.dbd ||
                      scope.row.corporateNumber == '0000000000000'
                    "
                    class="el-icon-warning"
                  ></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="year"
              label="ปี"
              align="center"
              header-align="center"
              width="80px"
            >
              <template slot-scope="scope">
                {{ Number(scope.row.year) + 543 }}
              </template>
            </el-table-column>
            <el-table-column
              prop="businessSize"
              label="ขนาดธุรกิจ"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.businessSize == "S"
                      ? "S"
                      : scope.row.businessSize == "M"
                      ? "M"
                      : scope.row.businessSize == "L"
                      ? "L"
                      : "-"
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="businessType"
              label="ลักษณะธุรกิจ"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                {{ getLabelBusinessType(scope.row.businessType) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="industryType"
              label="ประเภทอุตสาหกรรม"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.industryType != 0">
                  {{
                    getLabelIndustryType(
                      scope.row.cateIndustryType,
                      scope.row.industryType
                    )
                  }}
                </div>
                <div v-else>
                  {{ scope.row.industryTypeEtc }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="projectName"
              label="โครงการ"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              prop="referName"
              label="แหล่งที่มา"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              prop="createdAt"
              label="สมัครเมื่อ"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.createdAt != 6">
                  {{ moment(scope.row.createdAt).format("lll น.") }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="สถานะ"
              align="center"
              header-align="center"
              width="100px"
            >
              <template slot-scope="scope">
                <el-tooltip placement="bottom" :open-delay="800">
                  <div slot="content">{{ scope.row.transactionType }}</div>
                  <span>
                    <i
                      class="fas fa-user-slash text-danger mg-r-7"
                      v-if="
                        scope.row.transactionTypeId == 1 ||
                        scope.row.transactionTypeId == 3
                      "
                    ></i>
                  </span>
                </el-tooltip>
                <el-tooltip
                  :content="
                    scope.row.recommendStatus == 1
                      ? 'ประเมินเรียบร้อยแล้ว'
                      : scope.row.descriptionRecommendStatus == 'กำลังประเมิน'
                      ? 'อยู่ระหว่างประเมิน'
                      : ''
                  "
                  :open-delay="500"
                  placement="bottom"
                >
                  <span
                    class="mg-r-7"
                    v-if="scope.row.recommendStatus == 1"
                    title="ประเมินเรียบร้อยแล้ว"
                  >
                    <i class="el-icon-document-checked text-primary"></i>
                  </span>
                  <span
                    class="mg-r-7"
                    v-if="
                      scope.row.descriptionRecommendStatus == 'กำลังประเมิน'
                    "
                    title="อยู่ระหว่างประเมิน"
                  >
                    <i class="el-icon-document text-warning"></i>
                  </span>
                </el-tooltip>
                <el-dropdown trigger="click" class="mg-r-6">
                  <span class="el-dropdown-link">
                    <span
                      :class="
                        scope.row.status == 0
                          ? 'color-grey-light'
                          : 'color-success'
                      "
                      >{{ getLabelStatusUser(scope.row.status) }}</span
                    >
                    <i
                      class="
                        fas
                        fa-ellipsis-v
                        mg-l-6
                        color-grey
                        cr-pointer
                        hover-primary
                      "
                    ></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item class="color-danger"
                      ><router-link
                        :to="`/system/user/company/edit/${filter.userId}/${scope.row.companyId}`"
                        class="text-grey"
                      >
                        แก้ไขข้อมูลบริษัท
                      </router-link></el-dropdown-item
                    >
                    <el-dropdown-item class="color-danger"
                      ><a
                        :href="`/statement/${scope.row.userId}/${scope.row.companyId}`"
                        class="text-grey"
                        target="_blank"
                      >
                        ข้อมูลงบการเงิน
                      </a></el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="24">
          <div class="mg-y-3">
            <div class="block text-center">
              <el-pagination
                v-if="totalUser"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-sizes="[15, 30, 50, 100, 500]"
                :page-size="15"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalUser"
              >
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from "@/template/DashboardAdminTemplate";
import { HTTP } from "@/service/axios";
export default {
  components: {
    DashboardTemplate
  },
  mounted() {
    this.fetchUserList();
  },
  data() {
    return {
      userList: [],
      totalUser: 0,
      filter: {
        search: "",
        userId: this.$route.params.id
      },
      currentPage: 1,
      debounce: null,
      isLoading: false,
      loading: false,
      debounce: null,
      yearNow: true,
      lastCompanyId: null,
      lastSyncDBD: null
    };
  },
  computed: {
    businessType() {
      return this.$store.state.businessType;
    },
    industryTypeOption() {
      let fitler = this.$store.state.industryType;
      return fitler;
    },
    memberOfOrganize() {
      return this.$store.state.memberOfOrganize;
    },
    statusList() {
      return this.$store.state.statusList;
    }
  },
  methods: {
    sysDbdByUserId(id) {
      this.loading = true;
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post(`system/admin/request/dbd/juristicId`, { userId: id })
        .then(result => {
          console.log(`system/admin/request/dbd/juristicId`, result);
          if (result.data.success) {
            if (result.data.dbd) {
              this.$message({
                message: "Sync ข้อมูลสำเร็จ",
                type: "success"
              });
            } else {
              this.$message.error({
                message: "เลขนิติบุคคลนี้ไม่มีข้อมูลตาม DBD"
              });
            }
            this.handleCurrentChange(this.currentPage);
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getLabelIndustryType(cateId, id) {
      let filter = [];
      filter = this.$store.state.industryType.filter(a => a.id == cateId);
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "-";
    },
    getLabelMemberOfOrganize(id) {
      let filter = this.memberOfOrganize.filter(filter => filter.value == id);
      if (filter.length > 0) {
        return filter[0].label;
      } else {
        return "-";
      }
    },
    getLabelStatusUser(id) {
      let filter = this.statusList.filter(filter => filter.value == id);
      if (filter.length > 0) {
        return filter[0].label;
      } else {
        return "-";
      }
    },
    getLabelBusinessType(id) {
      let filter = this.businessType.filter(filter => filter.value == id);
      if (filter.length > 0) {
        return filter[0].shortName;
      } else {
        return "";
      }
    },
    handleSizeChange(val) {
      this.filter.limit = val;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val, time = 0) {
      this.isLoading = true;
      this.currentPage = val;
      let end = this.currentPage * this.filter.limit;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.fetchUserList();
      }, time);
    },
    async fetchUserList() {
      this.isLoading = true;
      let obj = {
        ...this.filter
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.post(`system/admin/companies`, obj).catch(e => {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
      if (res.data.success) {
        this.userList = res.data.obj;
        this.totalUser = res.data.total;
        if (res.data.obj.length > 0) {
          this.lastCompanyId = res.data.obj[res.data.obj.length - 1].companyId;
          this.lastSyncDBD = res.data.obj[res.data.obj.length - 1].lastSyncDBD;
        }
        let year = res.data.obj.findIndex(
          a => a.year == new Date().getFullYear()
        );
        if (year == -1) {
          this.yearNow = false;
        }
      }
      this.isLoading = false;
    }
  }
};
</script>